// Libraries
import { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Input } from 'antd';

const { Search } = Input;

const SearchInput = ({ placeholder, ...props }) => {
  const { t } = useContext(TranslationsContext);

  return <StyledSearchInput placeholder={placeholder || t('Search...')} {...props} />;
};

const StyledSearchInput = styled(Search)`
  &&& {
    position: relative;

    input {
      &::placeholder {
        font-weight: 300;
      }

      padding: ${({ padding }) => padding || '10.5px 15.5px'};
      border-start-end-radius: 4px;
      border-end-end-radius: 4px;
    }

    .ant-input-group-addon {
      width: 100%;
      padding-right: 0.6em;
      background: none;

      position: absolute;
      top: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: end;

      pointer-events: none;
    }

    .ant-input-search-button {
      border: none;
      background: none;
      pointer-events: all;
      z-index: 1;

      .ant-wave {
        display: none;
      }

      &:focus-visible {
        border-radius: 4px;
      }
    }
  }
`;

SearchInput.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchInput;
