// Libraries
import styled from 'styled-components';

const SimpleTag = styled.span`
  color: ${props => props.color || 'grey'};
  background: ${props => (props.color ? `${props.color}1A` : '#8080801A')};
  padding: 0 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
`;

export default SimpleTag;
