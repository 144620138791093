import { call, put, all, takeLatest } from 'redux-saga/effects';
import mentorApplicationService from 'services/mentorApplication';
import types from './types';
import actions from './actions';

export function* listMentorApplication(action) {
  yield put(actions.listMentorApplicationPending());
  try {
    const response = yield call(
      mentorApplicationService.listMentorApplication,
      action.payload,
      action.programPhase
    );
    yield put(actions.listMentorApplicationSucceeded(response.data, action.programPhase));
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(actions.listMentorApplicationFailed({ message: e.message }));
  }
}

export function* listMatcheableMentorApplication(action) {
  yield put(actions.listMatcheableMentorApplicationPending());
  try {
    const response = yield call(
      mentorApplicationService.listMatcheableMentorApplication,
      action.payload
    );
    yield put(actions.listMatcheableMentorApplicationSucceeded(response.data));
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(actions.listMatcheableMentorApplicationFailed({ message: e.message }));
  }
}

export function* listAvailableMentor(action) {
  yield put(actions.listAvailableMentorPending());
  try {
    const response = yield call(mentorApplicationService.listAvailableMentor, action.payload);
    yield put(actions.listAvailableMentorSucceeded(response.data));
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(actions.listAvailableMentorFailed({ message: e.message }));
  }
}

export function* updateMentorApplicationBulk(action) {
  const { callback, payload, programPhase } = action;
  try {
    const update = yield call(
      mentorApplicationService.updateApplicationBulk,
      payload,
      programPhase
    );
    yield put(
      actions.updateMentorApplicationBulkSucceeded(
        { ...update.data, ids: payload.ids },
        programPhase
      )
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateMentorApplicationBulkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* moreActionsApplicationManagementPanel(action) {
  const { callback, payload, programPhase } = action;
  try {
    yield call(mentorApplicationService.moreActionsApplicationManagementPanel, payload);
    yield put(
      actions.moreActionsApplicationManagementPanelSucceeded(
        { id: payload.id, project: payload.project },
        programPhase
      )
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.moreActionsApplicationManagementPanelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* currentApplication(action) {
  yield put(action.payload);
}

export function* fetchAppfetchManagementPanelMentorApplicationlication(action) {
  const { id, programPhase, program } = action.payload;
  try {
    const application = yield call(mentorApplicationService.fetchManagementPanelMentorApplication, {
      id,
      programPhase,
      program,
    });
    yield put(actions.fetchManagementPanelMentorApplicationSucceeded(application.data));
  } catch (e) {
    yield put(
      actions.fetchManagementPanelMentorApplicationFailed({
        message: e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.MENTOR_APPLICATIONS_LIST, listMentorApplication),
    takeLatest(types.MENTOR_APPLICATION_BULK_UPDATE, updateMentorApplicationBulk),
    takeLatest(
      types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS,
      moreActionsApplicationManagementPanel
    ),
    currentApplication,
    takeLatest(
      types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH,
      fetchAppfetchManagementPanelMentorApplicationlication
    ),
    takeLatest(types.MATCHEABLE_MENTOR_APPLICATIONS_LIST, listMatcheableMentorApplication),
    takeLatest(types.AVAILABLE_MENTOR_LIST, listAvailableMentor),
  ]);
}

export default authSaga;
