import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for fetching user data
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/user/',
  }),
  endpoints: builder => ({
    // Fetch the user status
    getUserStatus: builder.query({
      query: () => ({ url: 'status' }),
    }),
    // Fetch the user privacy settings
    getUserPrivacy: builder.query({
      query: () => ({ url: 'privacy/' }),
      providesTags: ['UserPrivacy'],
    }),
    // Update the user privacy settings
    updateUserPrivacy: builder.mutation({
      query: ({ id, ...updatedInterestship }) => ({
        url: 'privacy/',
        method: 'PATCH',
        data: updatedInterestship,
      }),
      invalidatesTags: ['UserPrivacy'],
    }),
  }),
});

export const { useGetUserStatusQuery, useGetUserPrivacyQuery, useUpdateUserPrivacyMutation } =
  userApi;
