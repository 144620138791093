import styled, { css } from 'styled-components';
import { TYPE_MAIN } from 'styles/themes';

const getToolColor = ({ colorIndex, theme }, defaultColor) =>
  colorIndex !== undefined ? theme.getToolColor(colorIndex) : defaultColor;

// STYLES
// Font weight
const boldStyle = css`
  font-weight: 700;
`;

const semiBoldStyle = css`
  font-weight: 600;
`;

const normalStyle = css`
  font-weight: 400 !important;
`;

const fontWeightStyle = css`
  ${({ $semibold }) => $semibold && semiBoldStyle};
  ${({ $bold }) => $bold && boldStyle};
  ${({ $fontWeightNormal }) => $fontWeightNormal && normalStyle}
`;

// Common font styles
const commonStyle = css`
  /* Margins */
  margin-top: ${({ $mt }) => $mt};
  margin-bottom: ${({ $mb0, $mb }) => {
    if ($mb0) return 0;
    if ($mb) return $mb;
    return '10px';
  }};

  /* Color */
  color: ${({ color, $primary, $colorIndex, $themeColor, theme }) => {
    if (color) return color;
    if ($primary) return theme.primaryColor; // BfB blue
    if ($colorIndex) return theme.getToolColor($colorIndex);
    if ($themeColor) return theme[$themeColor];
  }};

  /* Text position */
  text-align: ${({ $alignCenter, $alignRight }) => {
    if ($alignCenter) return 'center';
    if ($alignRight) return 'right';
  }};

  /* Text transform */
  text-transform: ${({ $capitalize, $uppercase, $lowercase }) => {
    if ($capitalize) return 'capitalize';
    if ($uppercase) return 'uppercase';
    if ($lowercase) return 'lowercase';
  }};

  /* Font style */
  ${fontWeightStyle}
  font-style: ${({ $italic }) => $italic && 'italic'};
`;

// HEADER
const headerColorStyle = css`
  color: ${({ color, theme, $colorIndex, $themeColor }) =>
    !(color || $colorIndex || $themeColor) && theme.primaryColor};
`;

// H1
const H1 = styled.h1`
  ${commonStyle}
  ${boldStyle}
  ${headerColorStyle}
  font-size: 20px;
  line-height: 30px;
`;

// H2
const H2 = styled.h2`
  ${commonStyle}
  ${boldStyle}
  ${headerColorStyle}
  font-size: 16px;
  line-height: 26px;
`;

// H3
const H3 = styled.h3`
  ${commonStyle}
  ${headerColorStyle}
  color: ${({ theme }) => theme.type === TYPE_MAIN && 'black'};
  font-size: 16px;
  line-height: 26px;
`;

// H4
const H4 = styled.h4`
  ${semiBoldStyle}
  ${commonStyle}
  ${headerColorStyle}
`;

// H5
const H5 = styled.h5`
  ${commonStyle}
  ${semiBoldStyle}
  ${headerColorStyle}
  font-size: 15px;
`;

// BODY
// P
const P = styled.p`
  ${commonStyle}
  font-size: 15px;
  line-height: 1.66667;

  ${({ tip, theme }) =>
    tip &&
    css`
      color: ${props => getToolColor(props, props.color || 'white')};
      background-color: ${theme.primaryColor};
      border-radius: 4px;
      padding: 1em;
    `};

  ${({ help }) =>
    help &&
    css`
      color: #37cc82;
      font-size: ${({ size }) => size || '12px'};
      font-style: italic;
      line-height: 1.66667;
    `}
`;

// Body
const Body = styled.p`
  ${commonStyle}
  color: black;
  font-size: 14px;
  margin-bottom: 0px;
`;

// Infotext
const InfoText = styled.p`
  ${commonStyle}
  ${semiBoldStyle};
  color: ${({ color, theme, $colorIndex, $themeColor }) =>
    !(color || $colorIndex || $themeColor) && theme.greyColor};
`;

// Span
const Span = styled.span`
  ${fontWeightStyle}

  ${({ $wrapText }) =>
    $wrapText &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

// List
const Ul = styled.ul`
  li::marker {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    line-height: 0;
  }

  ${({ $noBullets }) =>
    $noBullets &&
    css`
      list-style-type: none;
      padding: 0;
      margin: 0;
    `}
`;

// Highlight
const Highlight = styled.span`
  color: ${({ theme, themeColor }) => (themeColor && theme[themeColor]) || 'inherit'};
  background-color: ${({ theme }) => theme.highlightColor};
`;

// Other
const getBgColor = (options, theme) => {
  if (options.grey) return theme.lightGrey;

  return options.title ? theme.secondaryColor : theme.itemActiveBg;
};

const addEmStyles = (Component, options = { grey: false, title: false }) => styled(Component)`
  em {
    margin: ${({ margin }) => (margin ? '0 2px' : 0)};
    padding: 2px 4px;
    font-style: normal;
    background-color: ${({ theme }) => getBgColor(options, theme)};
    ${options.title && 'border-radius: 0'};
  }
`;

const addStrongStyles = Component => styled(Component)`
  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export {
  // Elements
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  Span,
  Ul,
  InfoText,
  Body,

  // Styles
  commonStyle,
  semiBoldStyle,
  fontWeightStyle,
  Highlight,

  // Other
  addEmStyles,
  addStrongStyles,
  getToolColor,
};
