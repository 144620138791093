import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';
import { formatAsQueryParams } from 'utils/queryString';

const programPhaseValidator = stage => {
  const programPhase = ['application', 'selection', 'approved'].includes(stage)
    ? 'application'
    : stage;
  return programPhase;
};

export const managementPanelApi = createApi({
  reducerPath: 'managementPanelApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/management-panel/',
  }),
  endpoints: builder => ({
    // Update Application
    updateApplicationBulk: builder.mutation({
      query: ({ query = {}, ...data }) => ({
        url: `members/${programPhaseValidator(data.stage)}/bulk/?`,
        params: formatAsQueryParams(query, true), // Todo: test it applying filters in tables in PM Panel
        method: 'PATCH',
        data: data,
      }),
    }),
    // /available-mentors
    getAvailableMentors: builder.query({
      query: ({ data }) => ({
        url: 'available-mentors',
        params: { ...data },
        method: 'GET',
      }),
    }),
    // Potential approach to the other enpoints witht he same base URL (not tested):
    // // Fetch program : programs/${id}
    // updateApplicationBulk: builder.query({
    //   query: ({ id }) => ({
    //     url: `programs/${id}`,
    //   }),
    // }),
    // // Update program: ${payload.id}/ + payload
    // updateApplicationBulk: builder.mutation({
    //   query: ({id, ...payload } ) => ({
    //     url: `programs/${id}`,
    //     params: formatAsQueryParams(query, true), // come back to this later
    //     method: "PATCH",
    //     body: payload,
    //   }),
    // }),
  }),
});

export const { useUpdateApplicationBulkMutation, useGetAvailableMentorsQuery } = managementPanelApi;
