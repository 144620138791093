const MENTOR_APPLICATIONS_LIST = 'bfb/ManagementPanel/MENTOR_APPLICATIONS_LIST';
const MENTOR_APPLICATIONS_LIST_PENDING = 'bfb/ManagementPanel/MENTOR_APPLICATIONS_LIST_PENDING';
const MENTOR_APPLICATIONS_LIST_SUCCEEDED = 'bfb/ManagementPanel/MENTOR_APPLICATIONS_LIST_SUCCEEDED';
const MENTOR_APPLICATIONS_LIST_FAILED = 'bfb/ManagementPanel/MENTOR_APPLICATIONS_LIST_FAILED';
const MATCHEABLE_MENTOR_APPLICATIONS_LIST =
  'bfb/ManagementPanel/MATCHEABLE_MENTOR_APPLICATIONS_LIST';
const MATCHEABLE_MENTOR_APPLICATIONS_LIST_PENDING =
  'bfb/ManagementPanel/MATCHEABLE_MENTOR_APPLICATIONS_LIST_PENDING';
const MATCHEABLE_MENTOR_APPLICATIONS_LIST_SUCCEEDED =
  'bfb/ManagementPanel/MATCHEABLE_MENTOR_APPLICATIONS_LIST_SUCCEEDED';
const MATCHEABLE_MENTOR_APPLICATIONS_LIST_FAILED =
  'bfb/ManagementPanel/MATCHEABLE_MENTOR_APPLICATIONS_LIST_FAILED';
const AVAILABLE_MENTOR_LIST = 'bfb/ManagementPanel/AVAILABLE_MENTOR_LIST';
const AVAILABLE_MENTOR_LIST_PENDING = 'bfb/ManagementPanel/AVAILABLE_MENTOR_LIST_PENDING';
const AVAILABLE_MENTOR_LIST_SUCCEEDED = 'bfb/ManagementPanel/AVAILABLE_MENTOR_LIST_SUCCEEDED';
const AVAILABLE_MENTOR_LIST_FAILED = 'bfb/ManagementPanel/AVAILABLE_MENTOR_LIST_FAILED';
const MENTOR_APPLICATION_BULK_UPDATE = 'bfb/ManagementPanel/MENTOR_APPLICATION_UPDATE';
const MENTOR_APPLICATION_BULK_UPDATE_SUCCEEDED =
  'bfb/ManagementPanel/MENTOR_APPLICATION_BULK_UPDATE_SUCCEEDED';
const MENTOR_APPLICATION_BULK_UPDATE_FAILED =
  'bfb/ManagementPanel/MENTOR_APPLICATION_BULK_UPDATE_FAILED';
const CURRENT_APPLICATION = 'bfb/ManagementPanel/CURRENT_APPLICATION';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_SUCCEEDED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_SUCCEEDED';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_FAILED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_FAILED';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_SUCCEEDED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_SUCCEEDED';
const MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_FAILED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_FAILED';

export default {
  MENTOR_APPLICATIONS_LIST,
  MENTOR_APPLICATIONS_LIST_PENDING,
  MENTOR_APPLICATIONS_LIST_SUCCEEDED,
  MENTOR_APPLICATIONS_LIST_FAILED,
  MATCHEABLE_MENTOR_APPLICATIONS_LIST,
  MATCHEABLE_MENTOR_APPLICATIONS_LIST_PENDING,
  MATCHEABLE_MENTOR_APPLICATIONS_LIST_SUCCEEDED,
  MATCHEABLE_MENTOR_APPLICATIONS_LIST_FAILED,
  AVAILABLE_MENTOR_LIST,
  AVAILABLE_MENTOR_LIST_PENDING,
  AVAILABLE_MENTOR_LIST_SUCCEEDED,
  AVAILABLE_MENTOR_LIST_FAILED,
  MENTOR_APPLICATION_BULK_UPDATE,
  MENTOR_APPLICATION_BULK_UPDATE_SUCCEEDED,
  MENTOR_APPLICATION_BULK_UPDATE_FAILED,
  CURRENT_APPLICATION,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_SUCCEEDED,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_FAILED,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_SUCCEEDED,
  MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_FAILED,
};
