import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing the community profiles

export const communityProfilesApi = createApi({
  reducerPath: 'communityProfilesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/community/profiles/',
  }),
  endpoints: builder => ({
    // List ALL the profiles
    listCommunityProfiles: builder.query({
      query: ({ offset, limit, filters }) => ({
        params: { limit, offset, ...filters },
      }),
    }),
    // Fetch a profile by its ID
    getProfile: builder.query({
      query: id => ({ url: `${id}/` }),
    }),
  }),
});

export const { useListCommunityProfilesQuery, useGetProfileQuery } = communityProfilesApi;
