import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/organizations/',
  }),
  endpoints: builder => ({
    // List ALL organizations
    listOrganizations: builder.query({
      query: query => ({ params: query }),
    }),
    // Fetch an organization
    getOrganizationById: builder.query({
      query: organizationId => ({ url: `${organizationId}/` }),
    }),
  }),
});

export const {
  useListOrganizationsQuery,
  useGetOrganizationByIdQuery,
  useLazyGetOrganizationByIdQuery,
} = organizationsApi;
