import types from './types';

const listMentorApplication = (payload, programPhase, callback) => ({
  type: types.MENTOR_APPLICATIONS_LIST,
  payload,
  programPhase,
  callback,
});

const listMentorApplicationPending = () => ({
  type: types.MENTOR_APPLICATIONS_LIST_PENDING,
});

const listMentorApplicationSucceeded = (payload, programPhase) => ({
  type: types.MENTOR_APPLICATIONS_LIST_SUCCEEDED,
  payload,
  programPhase,
});

const listMentorApplicationFailed = ({ message }) => ({
  type: types.MENTOR_APPLICATIONS_LIST_FAILED,
  payload: { message },
});

const listMatcheableMentorApplication = (payload, callback) => ({
  type: types.MATCHEABLE_MENTOR_APPLICATIONS_LIST,
  payload,
  callback,
});

const listMatcheableMentorApplicationPending = () => ({
  type: types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_PENDING,
});

const listMatcheableMentorApplicationSucceeded = (payload, programPhase) => ({
  type: types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_SUCCEEDED,
  payload,
});

const listMatcheableMentorApplicationFailed = ({ message }) => ({
  type: types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_FAILED,
  payload: { message },
});

const listAvailableMentor = (payload, callback) => ({
  type: types.AVAILABLE_MENTOR_LIST,
  payload,
  callback,
});

const listAvailableMentorPending = () => ({
  type: types.AVAILABLE_MENTOR_LIST_PENDING,
});

const listAvailableMentorSucceeded = (payload, programPhase) => ({
  type: types.AVAILABLE_MENTOR_LIST_SUCCEEDED,
  payload,
});

const listAvailableMentorFailed = ({ message }) => ({
  type: types.AVAILABLE_MENTOR_LIST_FAILED,
  payload: { message },
});

const updateMentorApplicationBulk = (payload, callback, programPhase) => ({
  type: types.MENTOR_APPLICATION_BULK_UPDATE,
  payload,
  callback,
  programPhase,
});

const updateMentorApplicationBulkSucceeded = (payload, programPhase) => ({
  type: types.MENTOR_APPLICATION_BULK_UPDATE_SUCCEEDED,
  payload,
  programPhase,
});

const updateMentorApplicationBulkFailed = ({ message }) => ({
  type: types.MENTOR_APPLICATION_BULK_UPDATE_FAILED,
  payload: { message },
});

const currentApplication = payload => ({
  type: types.CURRENT_APPLICATION,
  payload,
});

const fetchManagementPanelMentorApplication = payload => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH,
  payload,
});

const fetchManagementPanelMentorApplicationSucceeded = payload => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_SUCCEEDED,
  payload,
});

const fetchManagementPanelMentorApplicationFailed = ({ message }) => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_FAILED,
  payload: { message },
});

const moreActionsApplicationManagementPanel = (payload, callback, programPhase) => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS,
  payload,
  callback,
  programPhase,
});

const moreActionsApplicationManagementPanelSucceeded = (payload, programPhase) => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_SUCCEEDED,
  payload,
  programPhase,
});

const moreActionsApplicationManagementPanelFailed = ({ message }) => ({
  type: types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_FAILED,
  payload: { message },
});

export default {
  listMentorApplication,
  listMentorApplicationPending,
  listMentorApplicationSucceeded,
  listMentorApplicationFailed,
  listMatcheableMentorApplication,
  listMatcheableMentorApplicationPending,
  listMatcheableMentorApplicationSucceeded,
  listMatcheableMentorApplicationFailed,
  listAvailableMentor,
  listAvailableMentorPending,
  listAvailableMentorSucceeded,
  listAvailableMentorFailed,
  updateMentorApplicationBulk,
  updateMentorApplicationBulkSucceeded,
  updateMentorApplicationBulkFailed,
  currentApplication,
  fetchManagementPanelMentorApplication,
  fetchManagementPanelMentorApplicationSucceeded,
  fetchManagementPanelMentorApplicationFailed,
  moreActionsApplicationManagementPanel,
  moreActionsApplicationManagementPanelSucceeded,
  moreActionsApplicationManagementPanelFailed,
};
