import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Cascader as AntCascader } from 'antd';

class Cascader extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.instanceOf(Object),
  };

  static defaultProps = {
    name: '',
    onChange: null,
    options: [],
  };

  handeOnChange = (value, selectedOptions) => {
    const { name, onChange } = this.props;
    if (onChange) {
      onChange({ target: { name, value } }, selectedOptions);
    }
  };

  render() {
    const { options, ...rest } = this.props;
    return (
      <AntCascader options={options} mode="multiple" onChange={this.handeOnChange} {...rest} />
    );
  }
}

const StyledCascader = styled(Cascader)`
  &&& {
    input {
      font-style: italic;
      font-weight: 200;
    }
    .ant-cascader-picker-label {
      text-decoration: none;
      top: 38%;
      line-height: 25px;
      min-height: 25px;
      padding: 0;
    }
    .ant-input {
      font-family: 'Source Sans 3';
      background-color: green;
      border: none;
      border-radius: 0;
      border-bottom: 1.5px solid #b3b3b3;
      &:focus {
        border-color: ${({ theme }) => theme.primaryColor};
        /* box-shadow: none;
        outline: none;         FIXME*/
      }
    }
    i.ant-cascader-picker-clear {
      color: #00ffaf;
    }
  }
`;

StyledCascader.Option = AntCascader.Option;

export default StyledCascader;
