import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';
import { MENTOR_STATUS_MAP, getPMStageByApplicationStatus } from 'models/MentorApplication';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: MENTOR_STATUS_MAP.reduce(
      (data, stage) => ({
        ...data,
        [stage]: {
          results: [],
          count: 0,
        },
        matcheables: {
          results: [],
          count: 0,
        },
        availables: {
          results: [],
          count: 0,
        },
      }),
      {}
    ),
    loading: false,
    availablesLoading: false,
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MENTOR_APPLICATIONS_LIST:
    case types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH:
    case types.MENTOR_APPLICATION_BULK_UPDATE:
    case types.MATCHEABLE_MENTOR_APPLICATIONS_LIST:
    case types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          updatePending: true,
          loading: true,
        })
      );
    case types.AVAILABLE_MENTOR_LIST:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          availablesLoading: true,
        })
      );
    case types.MENTOR_APPLICATIONS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            [action.programPhase]: {
              ...action.payload,
            },
          },
          loading: false,
          updatePending: false,
        })
      );
    case types.MENTOR_APPLICATION_BULK_UPDATE_SUCCEEDED: {
      const { fields, ids } = action.payload;
      const newResults = state.data[action.programPhase].results.map(app => {
        if (ids.includes(app.id)) {
          const newApp = { ...app, ...fields };
          return newApp;
        }
        return app;
      });
      let updatedResult = newResults;
      // for the rejected/dropped case, we need to remove the element from the list
      if (fields.hasOwnProperty('dropped') || fields.hasOwnProperty('rejected')) {
        updatedResult = newResults.filter(result => !ids.includes(result.id));
      }
      return reduxHelper.mergeState(
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            [action.programPhase]: {
              results: updatedResult,
              count: updatedResult.length,
            },
            loading: false,
            updatePending: false,
            availablesLoading: false,
          },
        })
      );
    }
    case types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_SUCCEEDED: {
      const programPhase = getPMStageByApplicationStatus(action.payload.status);
      const index = state.data[programPhase].results.findIndex(
        result => result.id === action.payload.id
      );
      const updatedResults = state.data[programPhase].results.toSpliced(index, 1, action.payload);
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            [programPhase]: {
              count: state.data[programPhase].count,
              results: updatedResults,
            },
          },
          loading: false,
          updatePending: false,
          availablesLoading: false,
        })
      );
    }
    case types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            matcheables: {
              ...action.payload,
            },
          },
          loading: false,
          updatePending: false,
          availablesLoading: false,
        })
      );
    case types.AVAILABLE_MENTOR_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            availables: {
              results: action.payload,
              count: action.payload.length,
            },
          },
          availablesLoading: false,
        })
      );
    case types.MANAGEMENT_PANEL_MENTOR_APPLICATION_MORE_ACTIONS_SUCCEEDED: {
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: state.data,
        })
      );
    }
    case types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_FAILED:
    case types.AVAILABLE_MENTOR_LIST_FAILED:
    case types.MENTOR_APPLICATIONS_LIST_FAILED:
    case types.MANAGEMENT_PANEL_MENTOR_APPLICATION_FETCH_FAILED:
    case types.MENTOR_APPLICATION_BULK_UPDATE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          updatePending: false,
          loading: false,
          availablesLoading: false,
          errorMessage: action.payload,
        })
      );
    case types.MENTOR_APPLICATIONS_LIST_PENDING:
    case types.MATCHEABLE_MENTOR_APPLICATIONS_LIST_PENDING:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          loading: true,
          updatePending: true,
        })
      );
    case types.CURRENT_APPLICATION:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          current: action.payload,
        })
      );
    default:
      return state;
  }
}
