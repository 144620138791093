import { Record } from 'immutable';

const UserRecord = Record({
  id: null,
  uuid: null,
  username: '',
  is_admin: false,
  member: null,
  mentor: null,
  is_active: false,
  customization: null,
});

class User extends UserRecord {
  get id() {
    return this.get('id');
  }

  isAdmin() {
    return this.get('is_admin');
  }

  isAuthenticated() {
    return this.get('id') !== null;
  }

  isMember() {
    return this.get('member') !== null;
  }

  isMentor() {
    return this.get('mentor') !== null;
  }

  isActive() {
    return this.get('is_active');
  }
}

export default User;

// Gender
export const GENDER_FEMALE = 'female';
export const GENDER_MALE = 'male';
export const GENDER_NA = 'na';
export const GENDER_NON_BINARY = 'nonbinary';

export const GENDER_CHOICES = [GENDER_FEMALE, GENDER_MALE, GENDER_NON_BINARY, GENDER_NA];

export const getGenderDisplay = (t, gender) =>
  ({
    [GENDER_FEMALE]: t('Female'),
    [GENDER_MALE]: t('Male'),
    [GENDER_NON_BINARY]: t('Non-binary'),
    [GENDER_NA]: t('Prefer not to answer'),
  })[gender];

// Entrepreneur or Mentor's Sections aka Areas of Innovation
export const areasOfInnovation = t => [
  { id: 1, name: t('Agriculture and Fishing') },
  { id: 2, name: t('Arts & Culture') },
  { id: 3, name: t('Assistance, Civic Society, & Human Rights') },
  { id: 4, name: t('Beauty & Fashion') },
  { id: 5, name: t('Cities, Housing, & Transportation') },
  { id: 6, name: t('Education & Training') },
  { id: 7, name: t('Energy & Environment') },
  { id: 8, name: t('Finance, Insurance, & Legal') },
  { id: 9, name: t('Health & Medical') },
  { id: 10, name: t('Travel, Food, & Entertainment') },
  { id: 11, name: t('Water & Sanitation') },
  { id: 12, name: t('IT & High-Tech') },
];
