import { formatAsQueryParams } from 'utils/queryString';
import api from './api';

const fetchApplication = ({ id }) => api.get(`/api/mentor_application/${id}/`);
const fetchManagementPanelMentorApplication = ({ id, programPhase, program }) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  return api.get(`/api/management-panel/mentors/${actualStage}/${id}/`, {
    params: { program, application_stage: programPhase },
  });
};

const createApplication = ({ mentorId, program, ...application }) =>
  api.post('/api/mentor_application/', {
    mentor: mentorId,
    program,
    ...application,
  });
const listApplications = () => api.get('/api/mentor_application/');

const listMatcheableMentorApplication = payload =>
  api.get(`/api/management-panel/matchable-mentors/`, {
    params: payload,
  });

const listAvailableMentor = payload =>
  api.get(`/api/management-panel/available-mentors/`, {
    params: payload,
  });

const updateApplication = application =>
  api.patch(`/api/mentor_application/${application.id}/`, { ...application });

const updateApplicationBulk = (payload = {}, programPhase = undefined) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  const payloadClone = { ...payload, query: payload.query ?? {} };
  const { query, ...rest } = payloadClone;

  const queryString = formatAsQueryParams(query, true);

  return api.patch(`/api/management-panel/mentors/${actualStage}/bulk/?${queryString}`, {
    params: query,
    ...rest,
  });
};

const moreActionsApplicationManagementPanel = (payload = {}) =>
  api.post(`/api/mentor-applications/${payload.id}/status`, {
    ...payload,
  });

const validateApplicationStatus = ({ applicationId }) => {
  api.get(`/api/mentor_application_status/?applicationId=${applicationId}`);
};

const listMentorApplication = (query = {}, programPhase) => {
  const actualStage = ['application', 'selection', 'approved'].includes(programPhase)
    ? 'application'
    : programPhase;
  return api.get(`/api/management-panel/mentors/${actualStage}`, {
    params: query,
  });
};

export default {
  createApplication,
  fetchApplication,
  listApplications,
  updateApplication,
  updateApplicationBulk,
  validateApplicationStatus,
  listMentorApplication,
  fetchManagementPanelMentorApplication,
  listMatcheableMentorApplication,
  moreActionsApplicationManagementPanel,
  listAvailableMentor,
};
